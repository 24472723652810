<!--团队设置：角色管理-->
<template>
  <div class="page-container">
    <div class="section">
      <div class="app-container">
        <!--角色管理页面-->
        <div v-show="show.role">
          <div class="page-header">
            <div class="ph-left">
              <!--页面标题-->
              <h2 class="page-title">角色管理</h2>
            </div>
          </div>
          <!--表格-->
          <div class="table-box">
            <el-table
              v-loading="loading.roleList"
              :data="roles"
              element-loading-text="Loading"
              border
              highlight-current-row
              style="width: 100%"
            >
              <!--序号-->
              <el-table-column label="序号" align="center" width="60">
                <template slot-scope="scope">
                  {{ scope.$index + 1 }}
                </template>
              </el-table-column>
              <!--角色名-->
              <el-table-column label="角色名" prop="name" align="center" />
              <!--创建时间-->
              <el-table-column prop="date" label="创建时间" align="center" />
              <!--操作-->
              <el-table-column label="操作" align="center">
                <template slot-scope="scope">
                  <div>
                    <el-button type="primary" size="mini" @click="toEditAuthorities(scope.row)">
                      <!--                  <svg-icon icon-class="icon_look" />-->
                      查看权限
                    </el-button>
                  </div>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </div>
        <!--角色权限页面-->
        <div v-show="show.auth">
          <!--页头-->
          <div class="page-header">
            <div class="ph-left">
              <!--页面标题-->
              <h2 class="page-title">角色权限</h2>
            </div>
            <div class="ph-right">
              <!--返回按钮-->
              <el-button type="primary" size="small" icon="el-icon-back" @click="toRolePage">
                返回
              </el-button>
            </div>
          </div>
          <!--表格-->
          <div class="table-box">
            <!--<div v-if="managePermission" class="control-box">-->
            <!--&lt;!&ndash;编辑&ndash;&gt;-->
            <!--<el-button v-if="visible.editBtn" type="primary" size="small" icon="el-icon-edit" @click="editOauthBtn">编辑</el-button>-->
            <!--&lt;!&ndash;保存&ndash;&gt;-->
            <!--<el-button v-if="visible.saveBtn" type="primary" size="small" icon="el-icon-success" @click="saveOauthBtn">保存</el-button>-->
            <!--</div>-->
            <el-table
              :data="authorities"
              element-loading-text="Loading"
              border
              highlight-current-row
              style="width: 100%"
            >
              <!--序号-->
              <el-table-column align="center" label="序号" width="100">
                <template slot-scope="scope">
                  {{ scope.$index + 1 }}
                </template>
              </el-table-column>
              <!--模块-->
              <el-table-column prop="name" label="模块" align="center" />
              <!--权限-->
              <el-table-column label="权限" header-align="center" min-width="200">
                <template slot-scope="scope">
                  <div style="padding-left: 15px">
                    <el-row v-if="scope.row.hasOwnProperty('view')" type="flex" justify="start">
                      <el-checkbox
                        v-model="scope.row.view"
                        style="width: 100px;"
                        :disabled="disable.checkbox"
                        @change="handelViewCheckChange(scope.row)"
                      >
                        查看
                      </el-checkbox>
                      <span :class="scope.row.view ? 'ygs-font-color-active' : ''">
                        {{ scope.row.view_desc }}
                      </span>
                    </el-row>
                    <el-row v-if="scope.row.hasOwnProperty('view_all')" type="flex" justify="start">
                      <el-checkbox
                        v-model="scope.row.view_all"
                        style="width: 100px;"
                        :disabled="disable.checkbox"
                        @change="handelViewAllCheckChange(scope.row)"
                      >
                        查看全部
                      </el-checkbox>
                      <span :class="scope.row.view_all ? 'ygs-font-color-active' : ''">
                        {{ scope.row.view_all_desc }}
                      </span>
                    </el-row>
                    <el-row v-if="scope.row.hasOwnProperty('operate')" type="flex" justify="start">
                      <el-checkbox
                        v-model="scope.row.operate"
                        style="width: 100px;"
                        :disabled="disable.checkbox"
                        @change="handelOperateCheckChange(scope.row)"
                      >
                        操作
                      </el-checkbox>
                      <span :class="scope.row.operate ? 'ygs-font-color-active' : ''">
                        {{ scope.row.operate_desc }}
                      </span>
                    </el-row>
                    <el-row v-if="scope.row.hasOwnProperty('manage')" type="flex" justify="start">
                      <el-checkbox
                        v-model="scope.row.manage"
                        style="width: 100px;"
                        :disabled="disable.checkbox"
                        @change="handelManageCheckChange(scope.row)"
                      >
                        管理
                      </el-checkbox>
                      <span :class="scope.row.manage ? 'ygs-font-color-active' : ''">
                        {{ scope.row.manage_desc }}
                      </span>
                    </el-row>
                  </div>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
// import { getAllModules, modifyAuthConfig } from '@/api/role'
import { mapGetters } from 'vuex'
import API from '@/api'
import { viewOauth, manageOauth } from '@/utils/validate'

export default {
  data() {
    return {
      roles: [
        {
          id: '',
          name: '',
          date: '',
          config: ''
        }
      ],
      authorities: [
        {
          id: '',
          name: '',
          manage: false,
          view: false
        }
      ],
      entity: {
        curAuthId: ''
      },
      visible: {
        editBtn: true,
        saveBtn: false
      },
      disable: {
        checkbox: true
      },
      show: {
        role: true,
        auth: false
      },
      loading: {
        roleList: false
      }
    }
  },
  computed: {
    ...mapGetters(['buttons']),
    viewPermission: {
      get: function() {
        return viewOauth('team_view', this.buttons)
      }
    },
    managePermission: {
      get: function() {
        return manageOauth('team_operate', this.buttons)
      }
    }
  },
  created() {
    API.userLog({ code: 'webRoleManage' }).then(() => {
    })
  },
  mounted() {
    this.getTeamRoles()
  },
  methods: {
    getTeamRoles() {
      this.loading.roleList = true
      API.getAllModules()
        .then(response => {
          this.roles = response.message.data
          this.loading.roleList = false
        })
        .catch(() => {
          this.loading.roleList = false
        })
    },
    editOauthBtn() {
      this.visible.editBtn = false
      this.visible.saveBtn = true
      this.disable.checkbox = false
    },
    saveOauthBtn() {
      this.$confirm('修改权限将退出登陆状态, 是否继续?', '操作', {
        closeOnClickModal: false,
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          for (const auth of this.authorities) {
            auth.manage ? (auth.manage = 1) : (auth.manage = 0)
            auth.view ? (auth.view = 1) : (auth.view = 0)
          }
          API.modifyAuthConfig({
            id: this.entity.curAuthId,
            config: JSON.stringify(this.authorities)
          })
            .then(() => {
              this.visible.saveBtn = false
              this.visible.editBtn = true
              this.$message.success('修改成功')
              this.getTeamRoles()
              this.disable.checkbox = true
              this._convertAuthConfig()
            })
            .catch(() => {})
        })
        .catch(() => {})
    },
    handelViewCheckChange(row) {
      if (row.manage) {
        row.view = true
      }
    },
    handelViewAllCheckChange(row) {
      if (row.view_all) {
        row.view_all = true
      }
    },
    handelOperateCheckChange(row) {
      if (row.operate) {
        row.operate = true
      }
    },
    handelManageCheckChange(row) {
      if (row.manage) {
        row.view = row.manage
      }
    },
    toRolePage() {
      this.show.role = true
      this.show.auth = false
    },
    toEditAuthorities(value) {
      this.entity.curAuthId = value.id
      this.authorities = JSON.parse(value.config)
      this._convertAuthConfig()
      this.show.role = false
      this.show.auth = true
      this.disable.checkbox = true
      this.visible.saveBtn = false
      this.visible.editBtn = true
      this.$forceUpdate()
    },
    _convertAuthConfig() {
      for (let i = 0; i < this.authorities.length; i++) {
        const auth = this.authorities[i]

        // if (auth.name === '印刷监测') {
        //   this.authorities.splice(7,1);
        // }
        // if (auth.name === '团队管理') {
        //   this.authorities.splice(9,1);
        // }
        // if (auth.name === '设备监测') {
        //   this.authorities.splice(11,1);
        // }
        if (auth.view) {
          auth.view = auth.view === 1
        }
        if (auth.view_all) {
          auth.view_all = auth.view_all === 1
        }
        if (auth.operate) {
          auth.operate = auth.operate === 1
        }
        if (auth.manage) {
          auth.manage = auth.manage === 1
        }
        if (auth.view === 0) {
          auth.view = false
        }
        if (auth.view_all === 0) {
          auth.view_all = false
        }
        if (auth.operate === 0) {
          auth.operate = false
        }
        if (auth.manage === 0) {
          auth.manage = false
        }
        this.authorities.splice(i, 1, auth)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
//.page-container{
//  padding: 40px;
//}
//.section{
//  padding: 30px 40px;
//}
.page-title{
  font-size: 20px;
}
.ygs-font-color-active {
  color: rgb(245, 180, 51);
}
</style>
